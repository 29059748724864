import { Box, Button } from "@mui/material"

const socialButtonsData = [
  {
    src: "./Socials/1.png",
    alt: "patreon",
    href: "https://www.patreon.com/cornhubai"
  },
  {
    src: "./Socials/2.png",
    alt: "twitter",
    href: "https://twitter.com/cornhub_ai"
  },
  {
    src: "./Socials/4.png",
    alt: "discord",
    href: "https://discord.com/invite/6hcthtMCvU"
  },
  {
    src: "./Socials/5.png",
    alt: "telegram",
    href: "https://t.me/cornhubai"
  }
]

const SocialButtons = () => {
  return (
    <Box>
      {socialButtonsData.map((button) => (
        <Button
          variant="plain"
          color="primary"
          component="a"
          href={button.href}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={button.src} alt={button.alt} className="btn-icon" />
        </Button>
      ))}
    </Box>
  )
}

export default SocialButtons
